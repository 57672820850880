<template>
  <div>
    <Section1 :keyWord="'La mejor solución para un préstamo con Asnef'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Prestamos con Asnef Rápidos y Seguros</strong></h2>

      <p>¿Necesitas un préstamo con rapidez para hacer frente a un pago o para un proyecto personal pero temes que te sea denegado por estar incluido en los ficheros de impagados de la Asnef?</p>
      <p>Te presentamos los nuevos <strong>préstamos con asnef de Ibancar.</strong> El sistema más rápido y seguro para disponer de liquidez en tu cuenta en un tiempo récord y completando todo el proceso de manera online. ¿Todavía no has solicitado el tuyo?</p>

      <h3 class="naranja"><strong>¿Qué es el ASNEF, cómo se llega hasta ahí?</strong></h3>
      <p>El Asnef es un fichero que pertenece a la Asociación Nacional de Establecimientos Financieros de Crédito. En este listado se incluye a todas las personas que han contraído una deuda con una empresa y no han satisfecho el pago.</p>
      <p>No importa la cantidad adeudada, y en muchas ocasiones ni siquiera está reconocida por el deudor por tratarse de un error de facturación o de una disconformidad (muy habitual en los contratos con operadoras de telefonía.)</p>
      <p><strong>¿Cómo nos afecta ASNEF a la hora de pedir un préstamo?</strong></p>
      <p>Las entidades bancarias tradicionales consultan los ficheros de la Asnef antes de conceder un préstamo a sus clientes. Si tu nombre aparece en el listado, se limitan a denegar el crédito sin molestarse en conocer el origen y la razón de la inclusión.</p>


      <h3 class="naranja"><strong>¿Cómo verifico si estoy en ASNEF?</strong></h3>
      <p>Para verificar si estás incluido en los ficheros de la Asnef debes ponerte en contacto con la Asociación Nacional de Establecimientos Financieros de Crédito a través de su teléfono de atención al consumidor o vía correo electrónico o tradicional.</p>

      <h3 class="naranja"><strong>¿Cómo salir de ASNEF?</strong></h3>
      <p>Salir de la Asnef no es sencillo. Una vez establezcas contacto con la Asociación puede que tengas que esperar varios días para recibir contestación, conocer el origen y el importe de la deuda y realizar las acciones necesarias para que te eliminen del fichero.</p>

      <h3 class="naranja"><strong>¿Vale la pena solicitar préstamos con ASNEF?</strong></h3>
      <p>Los <strong>préstamos con asnef</strong> son la mejor solución para conseguir liquidez inmediata sin depender de las entidades bancarias tradicionales y sus restricciones a la concesión de créditos.</p>
      <p><strong>Diferencia entre los créditos personales con ASNEF y los tradicionales</strong></p>
      <p>La rapidez, la sencillez en la tramitación y la flexibilidad de las condiciones son las principales diferencias entre los <strong>préstamos con asnef</strong> de Ibancar y los créditos tradicionales.</p>
      <p><strong>¿Cómo funcionan los préstamos con ASNEF?</strong></p>
      <p>Los <strong>préstamos con asnef</strong> de Ibancar se tramitan de manera 100% online. El cliente rellena un formulario con la cantidad solicitada y sus datos personales, recibe una propuesta de crédito personalizada y dispone del dinero en su cuenta en plazo aproximado de 24 horas. ¡Así de sencillo!</p>
      <p><strong>¿Cuáles son los requisitos de los mejores préstamos con ASNEF?</strong></p>
      <p>El único requisito para conseguir un <strong>préstamo con asnef</strong> en Ibancar es aportar la documentación requerida en la solicitud y aceptar la propuesta personalizada de crédito. Además, cuentas con la ayuda de un gestor para facilitar y agilizar el proceso.</p>
      <p><strong>¿Cómo solicitar créditos con ASNEF?</strong></p>
      <p>Solo tienes que rellenar el formulario de solicitud y seguir las instrucciones. También puedes contactar con Ibancar vía telefónica para obtener asesoramiento personalizado.</p>
      <p><strong>¿Es difícil pedir un <strong>préstamo con ASNEF</strong>?</strong></p>
      <p>Solicitar un <strong>préstamo con Asnef</strong> en Ibancar es muy sencillo. Lo único que necesitas es una conexión a internet y una cuenta corriente en la que ingresar el importe solicitado. El sistema se encargará de guiarte paso a paso durante toda la tramitación.</p>
      <p>¿Tengo derechos como usuario si estoy en ASNEF?</p>
      <p>Cuando solicitas un <strong>préstamo con Asnef</strong> en Ibancar tienes los mismos derechos que un cliente que no se encuentre en los ficheros de impagados. También tendrás acceso a un pack de servicios adicionales exclusivo para clientes de Ibancar.</p>
      <p><strong>¿Cuáles son las tasas de interés y condiciones de los préstamos con ASNEF?</strong></p>
      <p>Las tasas de interés y las condiciones de los préstamos con asnef de Ibancar se gestionan de manera flexible y personalizada. Cuentas con varias opciones disponibles para que el crédito se ajuste al máximo a tus necesidades.</p>

      <h3 class="naranja"><strong>Solicitar préstamos ASNEF sin nómina</strong></h3>
      <p>Los <strong>préstamos con asnef</strong> de Ibancar pueden solicitarse incluso sin estar en posesión de una nómina de trabajo. Muchos clientes han obtenido su crédito aportando fuentes de ingreso alternativas que son igualmente válidas.</p>
      <p><strong>Créditos con ASNEF para Desempleados, Jóvenes o Jubilados</strong></p>
      <p>Los <strong>préstamos con asnef</strong> de Ibancar están disponibles tanto para desempleados, como para personas jóvenes o pensionistas. Una fuente de ingresos es suficiente para solicitar los créditos personales con asnef de Ibancar.</p>

      <h3 class="naranja"><strong>Devolver un crédito con ASNEF o RAI</strong></h3>
      <p>Devolver un <strong>préstamo con asnef</strong> en Ibancar es muy sencillo, ya que en el momento de la contratación del servicio contarás con un gestor personal que te asesorará para que elijas la mejor opción para tu caso.</p>

      <h3 class="naranja"><strong>¿Y si no devuelvo el crédito con ASNEF a tiempo?</strong></h3>
      <p>¿Por qué no ibas a devolver el crédito con Asnef a tiempo? En Ibancar cuentas con todas las facilidades y el apoyo de un equipo de gestores para que nunca te veas en la situación de no poder devolver el importe solicitado.</p>

      <h3 class="naranja"><strong>Conclusiones ¿Por qué un préstamo personal con ASNEF puede ser útil para ti?</strong></h3>
      <p>Acceder a un <strong>préstamo con asnef</strong> en Ibancar te permitirá conseguir liquidez en tu cuenta en menos de 24 horas sin importar que te hayan incluido en un fichero de impagados.</p>
      <p>Esto es prácticamente imposible de conseguir en una entidad bancaria tradicional. Además, el trámite se realiza de manera online y en cuestión de minutos. Sin reuniones, sin papeleos y sin la necesidad de implicar a una tercera persona como aval.</p>
      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
          <div class="card m-4"  >
            <a href="../prestamo-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamo por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Entra aquí y conoce todo lo que debes saber para conseguir un préstamo por tu coche y resolver tus problemas.</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue un crédito por tu coche. Crédito por tu coche Online</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../dinero-rapido-con-tu-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Dinero rápido con tu coche</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero fácil con tu coche. Préstamo rápido y fácil por tu coche. Préstamos online por tu coche.</p>
              </div>
            </a>
          </div>
      </div>
      
      
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-madrid" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en Madrid</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Madrid. Recibe dinero por tu coche en Madrid</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-sevilla" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en &nbsp; Sevilla</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Credito por tu coche en Sevilla. Recibe dinero por tu coche en Sevilla</p>
              </div>
            </a>
          </div>
          <div class="card m-4">
            <a href="../credito-por-tu-coche-en-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito por tu coche en Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Crédito por tu coche en Valencia. Recibe dinero por tu coche en Valencia.</p>
              </div>
            </a>
          </div>
      </div>

      
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-las-palmas" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche Las &nbsp; Palmas</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero usando tu coche como aval sin cambiar de titularidad.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-asnef-y-nomina-rapidos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con Asnef y nómina rápidos</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Ya puedes conseguir préstamos con Asnef y nómina de manera muy sencilla.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-con-aval-de-coche-sin-cambiar-de-titular" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval de coche sin cambiar de titular</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
      </div> 

<!-- fila landing 4  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
        <div class="card m-4"  >
            <a href="../prestamos-con-aval-del-coche-de-mas-de-10-anos" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos con aval del coche de mas de 10 años</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos toda la información que necesitas leer sobre esto.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-por-tu-coche-alicante" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos por tu coche Alicante</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">¿Necesitas un préstamo por tu coche en Alicante? Pues, tenemos la solución, entra aquí.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../prestamos-aval-coche-valencia" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Préstamos aval coche &nbsp; Valencia</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche sin cambiar la titularidad.</p>
              </div>
            </a>
          </div>
    
      </div>

<!-- fila landing 5  -->
      <div class="d-flex flex-row justify-content-center flex-wrap">
          <div class="card m-4"  >
            <a href="../credito-aval-coche-con-asnef" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito aval coche con Asnef</h5>
                  <span class="caret-sign-city">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Consigue dinero con tu coche estando en Asnef.</p>
              </div>
            </a>
          </div>
          <div class="card m-4"  >
            <a href="../credito-coche" class="no">
              <div class="card-body">
                <h5 class="card-title naranja inline">Crédito con aval</h5>
                  <span class="caret-sign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                    </svg>
                  </span>
                <p class="card-text pl-1 pt-2">Tenemos la solución para un crédito por tu coche con aval.</p>
              </div>
            </a>
          </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'PrestamosAsnefRapidos',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Prestamos con Asnef Rápidos y Seguros | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'¿Necesitas un préstamo con rapidez para hacer frente a un pago o para un proyecto personal pero temes que te sea denegado por estar incluido en los ficheros de impagados de la Asnef?'},
        {name:'keywords', content:'prestamos con asnef'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>